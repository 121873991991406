<template>
  <miscellaneous>
    <span class="icon-page-misc mb-2">
      <feather-icon
        icon="CoffeeIcon"
      />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">
        Você foi desconectado!
      </h2>
      <p class="text-center">
        Sua sessão expirou. Faça novamente o login para acessar a plataforma.
      </p>
    </div>

    <button-form
      @action="handleRedirectToLogin"
    >
      Ir para o início!
    </button-form>
  </miscellaneous>
</template>

<script>
import Miscellaneous from '@/components/Miscellaneous/Miscellaneous.vue'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'

export default {
  name: 'Disconnected',

  components: {
    Miscellaneous,
    ButtonForm,
  },

  methods: {
    handleRedirectToLogin() {
      this.$router.replace({ name: 'home' })
    },
  },
}
</script>

<style scoped lang="scss">
  @import '../../assets/scss/page-misc';
</style>
